import * as React from "react";
import ContentSection from "../../components/contentSection";
import Layout from "../../components/layout";
import PageTitle from "../../components/pageTitle";
import SEO from "../../components/seo";
import potomac from "../../images/potomac.jpg";
import adobeLogo from "../../images/adobe-logo.png";
import vmwareLogo from "../../images/vmware-logo.png";
import dellLogo from "../../images/dell-logo.png";
import microsoftLogo from "../../images/microsoft-logo.png";
import fireeyeLogo from "../../images/fireeye-logo.jpg";
import awsLogo from "../../images/aws-logo.png";
import uptickLogo from "../../images/uptick-logo.png";
import { PartnerLogo } from "../../models";

const logos: PartnerLogo[] = [
    { src: adobeLogo, alt: "Adobe" },
    { src: vmwareLogo, alt: "VMWare Partner" },
    { src: dellLogo, alt: "Dell PartnerDirect" },
    { src: microsoftLogo, alt: "Microsoft Partner" },
    { src: fireeyeLogo, alt: "FireEye" },
    { src: awsLogo, alt: "Amazon Web Services (AWS) Consulting Partner" },
    {
        src: uptickLogo,
        alt: "UPTick Enterprise Solutions",
        href: "https://uptickenterprisesolutions.com"
    }
];

const Commercial: React.FunctionComponent = () => (
    <Layout>
        <SEO title="Commercial Services" />

        <PageTitle title="Commercial Services" imageUrl={potomac} />

        <ContentSection>
            <p>
                As a growing business ourselves, we understand the challenges
                you face in maintaining your standards of excellence despite the
                difficult financial climate. We have provided exceptional
                business value to our commercial clients, whether through our
                software development offerings, business intelligence
                capabilities, IT services, or virtualization expertise. With
                nearly 20 years of providing services to commercial clients, big
                and small, we pride ourselves in being able to provide
                unparalleled honesty, value, and quality.
            </p>
        </ContentSection>

        <ContentSection title="Reseller Partners">
            <div className="d-flex justify-content-around align-items-center flex-wrap">
                {logos.map((logo, i) => {
                    if (logo.href) {
                        return (
                            <a href={logo.href}>
                                <img
                                    key={`logo-${i}`}
                                    src={logo.src}
                                    alt={logo.alt}
                                    className="img-fluid m-2"
                                    style={{ maxHeight: 64 }}
                                />
                            </a>
                        );
                    }
                    return (
                        <img
                            key={`logo-${i}`}
                            src={logo.src}
                            alt={logo.alt}
                            className="img-fluid m-2"
                            style={{ maxHeight: 64 }}
                        />
                    );
                })}
            </div>
        </ContentSection>
    </Layout>
);

export default Commercial;
